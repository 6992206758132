import { Router } from '@reach/router';
import Posts from './components/posts';
import Post from './components/post';
import AddPost from './components/addpost';

function App() {
  return (
    <Router>
      <Posts path="/" />
      <AddPost path="/add" />
      <Post path="/posts/:id" />
    </Router>
  );
}

export default App;
