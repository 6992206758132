import React, { useState } from 'react';

const AddPost = () => {
  const [title, setTitle] = useState('');
  const [username, setUsername] = useState('');
  const [content, setContent] = useState('');
  const [published, setPublished] = useState('');

  const onChange = (field, e) => {
    if (field === 'title') {
      setTitle(e.target.value);
    } else if (field === 'username') {
      setUsername(e.target.value);
    } else if (field === 'content') {
      setContent(e.target.value);
    } else if (field === 'published') {
      setPublished(e.target.value);
    }
  };

  const onClick = async () => {
    await fetch(
      "https://serverless-api.joemoondev.workers.dev/api/posts", {
        method: 'POST',
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          title,
          username,
          content,
          published_at: published,
        })
      }
    );
  };

  return (
    <div>
      <form>
        <label>Title</label>
        <input type="text" onChange={(e) => onChange('title', e)} />
        <label>Username</label>
        <input type="text" onChange={(e) => onChange('username', e)} />
        <label>Content</label>
        <input type="text" onChange={(e) => onChange('content', e)} />
        <label>Published At</label>
        <input type="datetime-local" onChange={(e) => onChange('published', e)} />
        <button onClick={onClick}>Add Post</button>
      </form>
    </div>
  );
};

export default AddPost;
